<template>
    <div>
        <!-- banner Start -->
        <div class="banner-sec">
        <div class="header-img">
            <img src="@/assets/images/awards.jpg" class="w-100">
            <div class="contents">
                <h1>Awards & Recognition</h1>
                <p>Leadership Awards & Accolades</p>
            </div>
        </div>
    </div>
    <!-- banner end -->
    <!-- Awards Start -->
    <div class="container">
        <div class="about_section">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-9 col-lg-12" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="800">
                    <h2 class="mb-2 sub-title">AWARDS & RECOGNITION</h2>
                        <p class="mt-2 mb-5">Founded in 1977, the Ballaram Hanumandas Charitable Trust is engaged in nation building through education and development of the youth.
Guided by the beliefs and values of the illustrious Chaudhary family, the Trust is shaping young minds and creating the leaders of modern India through its initiatives, Gyankunj (Day School) ; Vidyagram (Fully Boys Residential School) and the BRCM College of Engineering and Technology. On anvil are institutions for a variety of fields in higher education,including Management and Architecture.</p>
                </div>

                <div class="col-12 col-sm-12 col-md-9 col-lg-12 mt-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="800">
                    <h2 class="mb-5 font-weight-bold"><img class="award_icon" src="@/assets/images/award-icon.png" />AWARDS & RECOGNITION</h2>
                </div>
            </div>
            
            <div class="row no-gutters awards_w" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="500">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <h2 class="award_year">1991-92</h2>
                        <div class="awardname">
                            <h5>All India Highest Export Award for Export of 100% Polyester Filament Fabric</h5>
                            <p>Awarded by : The Synthetics & Rayon Textile Export Promotion Council</p>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row no-gutters awards_w" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <h2 class="award_year">1992-93</h2>
                        <div class="awardname">
                            <h5>93 Highest Export of Polyester Filament Fabrics</h5>
                            <p>Awarded by : South Gujarat Textile Exporters Association</p>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row no-gutters awards_w" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <h2 class="award_year">1993-94</h2>
                        <div class="awardname">
                            <h5>Highest Export of Polyester Filament Fabrics</h5>
                            <p>Awarded by : Council for Small & Medium Scale, New Delhi</p>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <div class="awardname">
                            <h5>Regional 3rd Highest Export Award</h5>
                            <p>Awarded by : Canara Bank</p>
                        </div>

                    </div>
                </div>


            </div>

            <div class="row no-gutters awards_w" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <h2 class="award_year">1994-95</h2>
                        <div class="awardname">
                            <h5>Niryat Ratna and Gold Medal Award</h5>
                            <p>Awarded by : Council for Small & Medium Scale, New Delhi</p>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <div class="awardname">
                            <h5>Outstanding Export Performance in Cellulosic / NonCellulosic Group (2nd Price)</h5>
                            <p>Awarded by : The Southern Gujarat Chamber of Commerce & Industry, Surat</p>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <div class="awardname">
                            <h5>Best Export Award</h5>
                            <p>Awarded by : Canara Bank, Mumbai Circle</p>
                        </div>

                    </div>
                </div>


            </div>

            <div class="row no-gutters awards_w" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <h2 class="award_year">1995-96</h2>
                        <div class="awardname">
                            <h5>Unique Destination of Achieving the Prestigious Outstanding Export Performance Award in Textile Industry</h5>
                            <p>Awarded by : The Southern Gujarat Chamber of Commerce & Industry, Surat</p>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <div class="awardname">
                            <h5>Award of Export Achievement in Synthetics Filaments Fabrics (1st)</h5>
                            <p>Awarded by : South Gujarat Textile Exporters Association</p>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <div class="awardname">
                            <h5>Excellence in Export Award</h5>
                            <p>Awarded by : Canara Bank, Mumbai Circle</p>
                        </div>

                    </div>
                </div>


            </div>

            <div class="row no-gutters awards_w" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <h2 class="award_year">1996-97</h2>
                        <div class="awardname">
                            <h5>Outstanding Best Export Performance in Textile Industry</h5>
                            <p>Awarded by : The Southern Gujarat Chamber of Commerce & Industry, Surat</p>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row no-gutters awards_w" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <h2 class="award_year">1997-98</h2>
                        <div class="awardname">
                            <h5>National Export Award, Outstanding Export Performance</h5>
                            <p>Awarded by : Ministry of Commerce</p>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <div class="awardname">
                            <h5>Recognition for Excellent Performance in Export Gold Trophy - Niryat Shree Bandhu</h5>
                            <p>Awarded by : FIEO</p>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row no-gutters awards_w" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <h2 class="award_year">2000-01</h2>
                        <div class="awardname">
                            <h5>Highest Export Award Trophy – Certificate</h5>
                            <p>Awarded by : Council for Small & Medium Scale, New Delhi</p>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row no-gutters awards_w" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <h2 class="award_year">2003-04</h2>
                        <div class="awardname">
                            <h5>Certificate of Merit</h5>
                            <p>Awarded by : The Synthetics & Rayon, Textile Export Promotion Council</p>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row no-gutters awards_w" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <h2 class="award_year">2004-05</h2>
                        <div class="awardname">
                            <h5>Outstanding Best Export Performance in Textile Industry</h5>
                            <p>Awarded by : The Southern Gujarat Chamber of Commerce & Industry, Surat</p>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                       
                        <div class="awardname">
                            <h5>Silver Trophy</h5>
                            <p>Awarded by : The Synthetics & Rayon Textile Export Promotion Council.</p>
                        </div>

                    </div>
                </div>

            </div>

            <div class="row no-gutters awards_w" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <h2 class="award_year">2019-20</h2>
                        <div class="awardname">
                            <h5>Outstanding Performance in Domestic Market by Large Scale Unit</h5>
                            <p>Awarded by : The Dyestuffs Manufacturers Association of India</p>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">

                        <div class="awardname">
                            <h5>Recognition of Excellent Performance in Exports of Dyestuffs by a Large Scale Unit</h5>
                            <p>Awarded by : The Dyestuffs Manufacturers Association of India</p>
                        </div>

                    </div>
                </div>

            </div>

            <div class="row no-gutters awards_w" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">
                        <h2 class="award_year">2020-21</h2>
                        <div class="awardname">
                            <h5>Outstanding Performance in Domestic Market by Large Scale Unit</h5>
                            <p>Awarded by : The Dyestuffs Manufacturers Association of India</p>
                        </div>

                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4">
                    <div class="awards_box">

                        <div class="awardname">
                            <h5>Recognition of Excellent Performance in Exports of Dyestuffs by a Large Scale Unit</h5>
                            <p>Awarded by : The Dyestuffs Manufacturers Association of India</p>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
    <!-- Awards End -->

    </div>
  </template>

  
<script>

// aos animation 
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'animate.css/animate.min.css'

 export default {
          name: "awards",
          created() {
          document.title = "Awards - Pratibha Group";
          },
          mounted() {
              this.init();
          },
          methods: {
              init() {
                  this.aosAnimation();
              },
              aosAnimation(){
                  AOS.init({
                      easing: 'ease-in-out-sine'
                  });
              }
          }
      
  }
</script>