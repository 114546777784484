import Vue from 'vue'
import Router from 'vue-router'
import home from '@/components/home'
import about from '@/components/about-us'
import businesses from '@/components/businesses'
import awards from '@/components/awards'
import sustainability from '@/components/sustainability'
import contact from '@/components/contact-us'

Vue.use(Router)

export default new Router({
    routes: [{
            path: '/',
            name: 'home',
            component: home
        },
        {
            path: '/about',
            name: 'about',
            component: about
        },
        {
            path: '/businesses',
            name: 'businesses',
            component: businesses
        },
        {
            path: '/awards',
            name: 'awards',
            component: awards
        },
        {
            path: '/sustainability',
            name: 'sustainability',
            component: sustainability
        },
        {
            path: '/contact',
            name: 'contact',
            component: contact
        },
    ],
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})