<template>
    <div>

         <!-- banner Start -->
    <div class="banner-sec">
        <div class="header-img">
            <img src="@/assets/images/contact.jpg" class="w-100">
            <div class="contents">
                <h1>Contact Us</h1>
                <p>How can we help you?</p>
            </div>
        </div>
    </div>
    <!-- banner end -->
   <!-- Contact Us Start -->
   <div class="contact-us-section">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-3 mb-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="800">
                    <div class="row contact-details mb-4">

                        <div class="col-lg-4">
                            <div class="contact-box">
                                <div class="border-right">
                                    <img src="@/assets/images/location.png" class="max-50">
                                </div>
                                <div>
                                    <h5 class="text-center">Address</h5>
                                    <p class="text-center">
                                        Block No. 309, N.H.No. 8, <br />Baleshwar-Palsana,<br />
                                        Surat - 394317, Gujarat, India.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="contact-box">
                                <div class="border-right">
                                    <img src="@/assets/images/phone.png" class="max-50">
                                </div>
                                <div>
                                    <h5 class="text-center">Phone Number</h5>
                                    <p class="text-center">
                                        <a href="tel:+91 261 289 7741">+91-261-289-7741</a><br />
                                        <a href="tel:+91 261 289 7747">+91-261-289-7747</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="contact-box">
                                <div class="border-right">
                                    <img src="@/assets/images/open-mail.png" class="max-50">
                                </div>
                                <div>
                                    
                                    <h5 class="text-center">Email</h5>
                                    <p class="text-center">
                                        <a href="mailto:info@pratibhagroup.co.in">info@pratibhagroup.co.in</a><br />
                                        <a href="mailto:info@pratibhafabrics.co.in">info@pratibhafabrics.co.in</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- Contact Us End -->
    </div>
  </template>

  
<script>

// aos animation 
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'animate.css/animate.min.css'

 export default {
          name: "contact",
          created() {
          document.title = "Contact Us - Pratibha Group";
          },
          mounted() {
              this.init();
          },
          methods: {
              init() {
                  this.aosAnimation();
              },
              aosAnimation(){
                  AOS.init({
                      easing: 'ease-in-out-sine'
                  });
              }
          }
      
  }
</script>