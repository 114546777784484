 <template>
  
   <div>
 <!-- Footer Section Start -->
 <footer>
        <div class="container top_footer">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 pl-5 hidden_mobile" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="800">
                    <h3>OTHER LINKS <span class="footer_line"></span></h3>
                    <ul class="footermenu">
                        <li><router-link to="/">Home</router-link></li>
                        <li><router-link to="/about">About</router-link></li>
                        <li><router-link to="/businesses">Businesses</router-link></li>
                        <li><router-link to="/awards">Awards</router-link></li>
                        <li><router-link to="/sustainability">Sustainability</router-link></li>
                        <li><router-link to="/contact">Contact Us</router-link></li>
                    </ul>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 pl-5 pr-5 hidden_mobile" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="800">
                    <h3>INDUSTRIES <span class="footer_line"></span></h3>
                    <ul class="footermenu">
                         
                        <li><router-link to="/businesses">Dyes & Chemicals</router-link></li>
                        <li><router-link to="/businesses">Linen Fabrics</router-link></li>
                        <li><router-link to="/businesses">Technical Textiles</router-link></li>
                        <li><router-link to="/businesses">Fabric Processing</router-link></li>
                        <li><router-link to="/businesses">Denim</router-link></li>
                        <li><router-link to="/businesses">Yarn Dyeing</router-link></li>
                        <li><router-link to="/businesses">Furnishing</router-link></li>
                    </ul>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 pl-5" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                    <h3>CONTACT US <span class="footer_line"></span></h3>
                    <ul class="footermenu">
                       
                        <li class="mt-2">
                        PRATIBHA GROUP<br />
                            Block No. 309, N.H.No. 8,<br />
                            Baleshwar-Palsana,<br />
                            Surat - 394317<br />
                            Gujarat, India.
                        </li>

                         <li><a href="mailto:info@pratibhagroup.co.in">info@pratibhagroup.co.in</a></li>
                        <li>+91 261 289 7741</li>

                    </ul>
                </div>

            </div>
        </div>
        <div class="bottom_footer">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12  text-center">

                        <span class="copyright">&copy;2024 <b>Pratibha Group.</b></span><span class="design-by">Website developed by:  <a href="http://www.setblue.com" target="_blank" title="+91 98985 98984">Setblue.com</a></span>
                    </div>


                </div>
            </div>
        </div>
    </footer>
    <!-- Footer Section End -->
   </div>
   
  </template>
  
  <script>
  export default {
        name: "pratibha_footer",
        
    }
  </script>