<template>
    <div>
    <!-- Slider Start -->
    <div class="slider-section">
        <div class="main_banner">
            <div class="owl-carousel owl-theme">
                <div class="item">
                    <div class="contents">
                        <h1 class="slider_text">SUCCESS & BUSINESS TRUST IN THE COUNTRY</h1>

                        <p class="slider_des">
                            Pratibha is focusing on new technologies and 
                            innovation to drive its business in India
                        </p>
                    </div>
                    <img class="banner_desktop" src="@/assets/images/banner.jpg" />
                    <img class="banner_mobile" src="@/assets/images/mobile-banner.jpg" />
                  
                </div>
               
            </div>
        </div>
    </div>
    <!-- Slider End -->
    <!-- About Section Start -->
    <div class="container" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="800">
        <div class="about_section">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 text-center position-relative">
                    <img src="@/assets/images/about.png" />
                    
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-6 text-left pdleft">
                    <h2 class="about_title">Established in the year 1982 in Surat, India “Pratibha” </h2>
                    <p class="pb-0">Pratibha Group is a multi-business conglomerate that specializes in manufacturing a broad array of superior quality textile products.</p>
                    <p class="pt-1">
                        Pratibha was established in 1982 in the industrial core of Surat City. With a background in textile weaving and manufacturing, Pratibha Group has a wealth of experience amassed over the last four decades which has allowed us to expand into the production of textile dyes, denim fabrics, linen fabrics, nylon chips, and yarn. Our exceptional quality and versatility in manufacturing a wide range of products have allowed us to establish a solid presence both within the country and across borders, allowing Pratibha to achieve global leadership in the textile industry.
                    </p>
                    <div class="ab-btn mt-4"><router-link to="/about">More</router-link> </div>
                    
                </div>

            </div>
        </div>
    </div>
    <!-- About Section End -->
    <!-- Products Section Start -->
    <div class="container">
        <h2 class="section_title text-center title-underline mb-4 pt-4">Businesses</h2>
        <div class="tabList mt-0 mb-4">
            <ul>
                <li data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="400">
                 
                        <router-link to="/businesses">
                        <img src="@/assets/images/Industrie01.png" class="tab_icon">
                        <div class="tab_name">DYES & CHEMICALS</div>
                        </router-link>
                </li>
                <li data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="500">
                    <router-link to="/businesses">
                        <img src="@/assets/images/Industrie02.png" class="tab_icon">
                        <div class="tab_name">LINEN & COTTON FABRIC</div>
                    </router-link>
                </li>
                <li data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="600">
                    <router-link to="/businesses">
                        <img src="@/assets/images/Industrie03.png" class="tab_icon">
                        <div class="tab_name">TECHNICAL TEXTILE</div>
                    </router-link>
                </li>
                <li style="margin-right:0px;" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="700">
                    <router-link to="/businesses">
                        <img src="@/assets/images/Industrie04.png" class="tab_icon">
                        <div class="tab_name">FABRIC PROCESSING</div>
                    </router-link>
                </li>


                <li data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="800">
                    <router-link to="/businesses">
                        <img src="@/assets/images/Industrie05.png" class="tab_icon">
                        <div class="tab_name">DENIM FABRIC</div>
                    </router-link>
                </li>
                <li data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="900">
                    <router-link to="/businesses">
                        <img src="@/assets/images/Industrie06.png" class="tab_icon">
                        <div class="tab_name">YARN DYEING</div>
                    </router-link>
                </li>

                <li data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="900">
                    <router-link to="/businesses">
                        <img src="@/assets/images/Industrie08.png" class="tab_icon">
                        <div class="tab_name">NYLON YARN</div>
                    </router-link>
                </li>

                <li style="margin-right:0px;" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="950">
                    <router-link to="/businesses">
                        <img src="@/assets/images/Industrie07.png" class="tab_icon">
                        <div class="tab_name">HOME FURNISHING FABRIC</div>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
    <!-- Products Section End -->
    <!-- Community Section Start -->
    <div class="community-bg">
        <div class="container">
            <h2 class="section_title text-center title-underline mb-4">Sustainability</h2>
            <div class="row">
                <div class="col-6 col-sm-6 col-md-3 col-lg-3 text-center mb-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="500">
                    <router-link class="common-box-shadow" to="/sustainability">
                        <img src="@/assets/images/environment.png">
                        <h5 class="community_title">Environment</h5>
                    </router-link>
                   
                </div>
                <div class="col-6 col-sm-6 col-md-3 col-lg-3 text-center mb-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="600">
                    <router-link class="common-box-shadow" to="/sustainability">
                        <img src="@/assets/images/education.png">
                        <h5 class="community_title">Education</h5>
                    </router-link>
                </div>

                <div class="col-6 col-sm-6 col-md-3 col-lg-3 text-center mb-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="700">
                    <router-link class="common-box-shadow" to="/sustainability">
                        <img src="@/assets/images/health.png">
                        <h5 class="community_title">Health</h5>
                    </router-link>
                </div>

                <div class="col-6 col-sm-6 col-md-3 col-lg-3 text-center mb-3" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="800">
                    <router-link class="common-box-shadow" to="/sustainability">
                        <img src="@/assets/images/Community.png">
                        <h5 class="community_title">Community Welfare</h5>
                    </router-link>
                </div>
                
            </div>
        </div>
    </div>
    <!-- Community Section End -->
    
    </div>
  </template>

  
  <script>

// aos animation 
import AOS from 'aos'
import 'aos/dist/aos.css'
import 'animate.css/animate.min.css'

   export default {
            name: "home",
            created() {
            document.title = "Pratibha Group";
            },
            mounted() {
                this.init();
            },
            methods: {
                init() {
                    this.preLoader();
                    this.homeSlider();
                    this.aosAnimation();
                },
                preLoader(){
                    $(function () {
                            $(".preload").fadeOut(2000, function () {
                                $(".content").fadeIn(1000);
                            });
                        });
                },
                aosAnimation(){
                    AOS.init({
                        easing: 'ease-in-out-sine'
                    });
                },
                homeSlider() {
                    var owl = $('.owl-carousel');
                    owl.owlCarousel({
                        items: 1,
                        loop: false,
                        nav: true,
                        dots: false,
                        margin: 0,
                        smartSpeed: 2000,
                        autoplay: false,
                        // animateOut: 'fadeOut',
                    });
                },
            }
    }
  </script>