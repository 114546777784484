import pratibha_header from "./components/shared/header";
import pratibha_footer from "./components/shared/footer";

//bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap/dist/css/bootstrap.min.css';


// Owl Carousel 
import 'owl.carousel/dist/owl.carousel.js';
import 'owl.carousel/dist/assets/owl.carousel.css';

// main css
import '@/assets/css/style.css';


export default {
    name: 'App',

    mounted() {
        this.init();
    },

    components: {
        pratibha_header,
        pratibha_footer,
    },

    methods: {
        init() {
            this.mainMenu();
            this.botoomTop();
        },


        mainMenu() {
            $('.btn-nav').on('click', function() {
                $(".responsive-navigation").toggleClass('open');
            });
            $('.navbar-nav .nav-link').on('click', function() {
                $(".responsive-navigation").removeClass('open');
            });
        },

        botoomTop() {

            var btn = $('#bottomtop');
            $(window).scroll(function() {
                if ($(window).scrollTop() > 300) {
                    btn.addClass('show');
                } else {
                    btn.removeClass('show');
                }
            });

            btn.on('click', function(e) {
                e.preventDefault();
                $('html, body').animate({ scrollTop: 0 }, '300');
            });

        }

    }
}