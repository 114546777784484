<template>
   <div>
    <div class="banner-space"></div>
        <!-- Main Menu Start -->
        <div class="main_menu_section">
        <div class="container position-relative">
            <div class="">
                <nav class="navbar navbar-expand-lg navbar-light w-100 responsive-navigation pt-2 pb-2">
                    <button class="navbar-toggler btn-nav" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <!-- <span class="navbar-toggler-icon"></span> -->
                        <i class="fa fa-bars"></i>
                        <i class="fa fa-close"></i>
                    </button>
                    <router-link class="navbar-brand pb-0 pt-0" to="/"><img src="@/assets/images/logo.png" alt="Logo"></router-link> 
                    <div class="collapse navbar-collapse bg-red-line" id="navbarTogglerDemo01">
                        <ul class="navbar-nav ml-auto responsive-menu-section mt-2">
                            <li class="nav-item">
                                <router-link v-bind:class="{ active: $route.name == 'home' }" class="nav-link" to="/">Home</router-link> 
                            </li>
                            <li class="nav-item dropdown">
                                <router-link v-bind:class="{ active: $route.name == 'about' }" class="nav-link" to="/about">About Us</router-link> 
                            </li>
                            <li class="nav-item dropdown">
                                <router-link v-bind:class="{ active: $route.name == 'businesses' }" class="nav-link" to="/businesses">Businesses</router-link> 
                            </li>
                            <li class="nav-item">
                                <router-link v-bind:class="{ active: $route.name == 'awards' }" class="nav-link" to="/awards">Awards</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link v-bind:class="{ active: $route.name == 'sustainability' }" class="nav-link" to="/sustainability">Sustainability</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link v-bind:class="{ active: $route.name == 'contact' }" class="nav-link" to="/contact">Contact Us</router-link>
                            </li>
                           
                        </ul>
                    </div>
                </nav>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
    <!-- Main Menu End -->

    <a id="bottomtop"><i class="fa fa-arrow-up" aria-hidden="true"></i></a>
   </div>
  </template>
  
  <script>

 

  export default {
        name: "pratibha_header",
    }
  </script>
  

